import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      customText: 'config/customText',
      clientRootCategoryId: 'config/clientRootCategory'
    }),
  },
  methods: {
    /**
     * Retrieves the custom text from configuration by area and language
     * @param area
     */
    findCustomText(area) {
      let lang = this.$route.query.lang || 'default'
      let customer = this.$helper.extractIdFromSlug(this.$route.params.slug)
      return this.customText
        && this.customText[customer]
        && this.customText[customer][area]
        && this.customText[customer][area][lang]
          ? this.customText[customer][area][lang]
          : null
    },
    async applyDesign(categoryId) {
      let routeId = this.$route.params.slug
      if (routeId) {
        routeId = this.$helper.extractIdFromSlug(routeId)
      }

      const category = await this.$api.findCategory(categoryId || routeId || this.clientRootCategoryId)
      if (category && !category.error) {
        this.$store.commit('app/setColors', {
          primary: category.attributes.find(attr => attr.name === 'Color_Primary')?.value,
          tertiary: category.attributes.find(attr => attr.name === 'Color_Tertiary')?.value,
          secondary: category.attributes.find(attr => attr.name === 'Color_Secondary')?.value,
        })

        // Resolve background image/color by id:-/rgba-pattern
        const backgroundAttribute = category.attributes.find(attr => attr.name === 'CMS-Background')?.value
        let backgroundCategoryId = this.$helper.extractPropertyFromAttribute(backgroundAttribute, 'id')
        if (backgroundCategoryId) {
          let backgroundCategory = await this.$api.findCategory(backgroundCategoryId)
          if (backgroundCategory
            && backgroundCategory.media
            && backgroundCategory.media.links) {
            const link = backgroundCategory.media.links.find(media => media.rel === 'preview')
            this.$store.commit('app/setBackground', {
              value: link.href,
              type: 'image',
            })
          }
        }

        // Resolve the rgba code by the given attribute
        if (backgroundAttribute && backgroundAttribute.startsWith('rgba')) {
          this.$store.commit('app/setBackground', {
            value: backgroundAttribute,
            type: 'color',
          })
        }

      // Provides the logo
      const logoAttribute = category.attributes.find(attr => attr.name === 'CMS-Logo')?.value
      let logoCategoryId = this.$helper.extractPropertyFromAttribute(logoAttribute, 'id')
      if (logoCategoryId) {
        let logoCategory = await this.$api.findCategory(logoCategoryId)
        if (logoCategory
          && logoCategory.media
          && logoCategory.media.links) {
          const link = logoCategory.media.links.find(media => media.rel === 'preview')
          this.$store.commit('app/setLogo', link.href)
        }
      } else {
        this.$store.commit('app/setLogo', null)
      }

      // Refresh UI
      this.$emit('updateUI')

      }

      return category
    }
  },
  inject: [
    '$helper',
  ]
}